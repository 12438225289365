import React from "react";

export default function AppleIcon({className}) {
  return (
      <svg viewBox="0 0 26 32" className={className}>
        <path
            d="M21.7144 16.9641C21.7593 21.7963 25.9535 23.4043 26 23.4249C25.9645 23.5383 25.3298 25.7165 23.7903 27.9664C22.4594 29.9116 21.0781 31.8496 18.9022 31.8898C16.7641 31.9292 16.0766 30.6219 13.6322 30.6219C11.1885 30.6219 10.4247 31.8496 8.40075 31.9292C6.30043 32.0086 4.70106 29.8257 3.35912 27.8877C0.617022 23.9233 -1.47851 16.6853 1.33526 11.7996C2.73308 9.3733 5.23109 7.83687 7.94245 7.79747C10.0049 7.75813 11.9516 9.18503 13.2124 9.18503C14.4724 9.18503 16.8381 7.46906 19.3251 7.72107C20.3662 7.7644 23.2886 8.14162 25.1652 10.8885C25.014 10.9822 21.6781 12.9242 21.7144 16.9641V16.9641ZM17.6962 5.09831C18.8112 3.74855 19.5617 1.86957 19.357 0C17.7497 0.0645989 15.8061 1.07106 14.6532 2.42007C13.62 3.61468 12.7152 5.52673 12.9593 7.3593C14.7509 7.49791 16.581 6.44892 17.6962 5.09831"
            fill="currentColor"/>
      </svg>
  )
}
