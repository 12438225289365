import React from "react";
import {JsonLd} from "react-schemaorg";
import {SITE_URL} from "@/lib/utils/utils";

export default function BreadcrumbSchema({crumbs}) {
  return (
      <JsonLd
          item={{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": crumbs.map(({name, path}, i) => ({
              "@type": "ListItem",
              "position": i + 1,
              name,
              "item": `${SITE_URL}/${path}`,
            }))
          }}
      />
  );
}
