import React from "react";
import styles from './LineHeader.module.scss';
import * as classnames from "classnames/bind";

const cx = classnames.bind(styles);

export default function LineHeader({className, children}) {

  return (
      <div className={classnames(styles.lineHeader, className)}>
        <div className={styles.left}>
          <div className={styles.line}/>
        </div>
        <div className={styles.content}>{children}</div>
        <div className={styles.right}>
          <div className={styles.line}/>
        </div>
      </div>
  )
}
