import React from "react";
import styles from './Styles.module.scss';
import classNames from 'classnames/bind';
import Button from "@/components/button/Button";
import Link from "next/link";
import firebase from "@/lib/auth/firebase";
import FacebookIconInverted from "@/components/svg/FacebookIconInverted";
import {useRouter} from "next/router";
import AppleIcon from "@/components/svg/AppleIcon";

const cx = classNames.bind(styles);

const firebaseAuth = firebase.auth();

const facebookProvider = new firebase.auth.FacebookAuthProvider();
const googleProvider = new firebase.auth.GoogleAuthProvider();
const appleProvider = new firebase.auth.OAuthProvider('apple.com');

export default function SsoButtons({className, email, disabled}) {

  const router = useRouter();
  const {query} = router;

  function onClickGoogle(e) {
    e.preventDefault();
    firebaseAuth.signInWithRedirect(googleProvider)
        .then(console.log)
        .catch(console.log);
  }

  function onClickApple(e) {
    e.preventDefault();
    firebaseAuth.signInWithRedirect(appleProvider)
        .then(console.log)
        .catch(console.log);
  }

  function onClickFacebook(e) {
    e.preventDefault();
    firebaseAuth.signInWithRedirect(facebookProvider)
        .then(console.log)
        .catch(console.log);
  }

  return <div className={cx(styles.ssoButtons, className)}>
    <Button className={cx(styles.button, styles.google)}
            disabled={disabled}
            onClick={onClickGoogle}>
      <img className={styles.googleIcon} src={"/images/icon-google.png"}/>
      Log in with Google
    </Button>
    <Button className={cx(styles.button, styles.facebook)}
            disabled={disabled}
            onClick={onClickFacebook}>
      <FacebookIconInverted className={styles.facebookIcon}/>
      Log in with Facebook
    </Button>
    <Button className={cx(styles.button, styles.apple)}
            onClick={onClickApple}>
      <AppleIcon className={styles.appleIcon}/>
      Log in with Apple
    </Button>
    {email && <Link href={{pathname: "/email-login", query}}>
      <a className={styles.link}>
        <Button className={styles.button}
                disabled={disabled}>
          Log In with Email Link
        </Button>
      </a>
    </Link>}
  </div>
}
