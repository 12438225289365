import React from "react";

export default function FacebookIconInverted({className}) {
    return (
        <svg viewBox="0 0 16 16"
             className={className}>
            <path
                d="M13.8439 0H2.15605C0.965319 0 0 0.965273 0 2.15603V13.8439C0 15.0346 0.96528 15.9999 2.15605 15.9999H7.92045L7.93027 10.2824H6.44486C6.25181 10.2824 6.09514 10.1263 6.09439 9.93329L6.08727 8.0903C6.08652 7.8962 6.24367 7.73847 6.43777 7.73847H7.92049V5.95767C7.92049 3.89107 9.18265 2.7658 11.0262 2.7658H12.539C12.7325 2.7658 12.8895 2.92271 12.8895 3.1163V4.67031C12.8895 4.86382 12.7326 5.0207 12.5392 5.02081L11.6108 5.02124C10.6082 5.02124 10.4141 5.49766 10.4141 6.19682V7.73851H12.6171C12.827 7.73851 12.9899 7.9218 12.9651 8.13026L12.7467 9.97325C12.7258 10.1496 12.5762 10.2825 12.3987 10.2825H10.4239L10.4141 16H13.844C15.0347 16 16 15.0347 16 13.844V2.15603C16 0.965273 15.0347 0 13.8439 0Z"
                fill="white"/>
        </svg>
    )
}
