import React, {useEffect, useState} from "react";
import styles from './Styles.module.scss';
import Layout from "@/components/layout/Layout";
import Button from "@/components/button/Button";
import EmailInput from "@/components/input/email/EmailInput";
import {useForm} from "react-hook-form";
import firebase from "@/lib/auth/firebase";
import {useRouter} from "next/router";
import {useContext} from "@/lib/utils/context";
import TextInput from "@/components/input/text/TextInput";
import LineHeader from "@/components/header/lineHeader/LineHeader";
import Link from "next/link";
import {isSubscribed} from "@/lib/utils/authUtils";
import SsoButtons from "@/components/button/ssoButtons/SsoButtons";
import BreadcrumbSchema from "@/components/schema/BreadcrumbSchema";

const firebaseAuth = firebase.auth();

export default function Login() {

  const {auth, authRedirectError, user, setError} = useContext();
  const router = useRouter();

  const [authError, setAuthError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const {register, handleSubmit, watch} = useForm();
  const formValues = watch();

  const onSuccess = router.query?.onSuccess;

  useEffect(() => {
    if (user) {
      redirect().catch(console.log);
    }
  }, [user])

  function getAuthRedirectErrorMessage() {
    switch (authRedirectError?.code) {
      case "auth/account-exists-with-different-credential":
        return "It looks like you've previously signed in with a different method. Please try again with the method you used last time.";
    }
    return <span>
      Whoops! It looks like there's a problem with that log in method.
      Please <Link href={"/contact-us"}><a>contact&nbsp;us</a></Link> or
      try a different log in method.
    </span>
  }

  async function redirect() {
    if (!user) {
      return;
    } else if (onSuccess) {
      await router.push(onSuccess);
    } else if (!isSubscribed(user)) {
      await router.push('subscribe');
    } else {
      await router.push(`/`);
    }
  }

  const onSubmit = async () => {
    try {
      setSubmitting(true);
      const {email, password} = formValues;
      await firebaseAuth.signInWithEmailAndPassword(email, password);
      await redirect()
    } catch (error) {

      if (error?.code === "auth/wrong-password" || error?.code === "auth/user-not-found") {
        setAuthError(" ");
      } else {
        setError(error);
      }
      setSubmitting(false);
    }
  };

  return (
      <Layout nav loading={!auth || auth.user}>
        <BreadcrumbSchema crumbs={[{name: "Login", path: "login"}]}/>
        <main className={styles.container}>
          <div className={styles.content}>
            <h2>Log in</h2>
            <div className={styles.desktopGrid}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {
                  authError && <p className={styles.authError}>
                    Whoops! There appears to be a problem. Please make sure you have entered the correct email
                    address or <Link href="/password-reset"><a>reset your password here</a></Link>
                  </p>
                }
                <div className={styles.fields}>
                  <EmailInput
                      required
                      name={"email"}
                      register={register}
                      disabled={submitting}
                  />
                  <span><Link href={"/password-reset"}><a
                      className={styles.passwordReset}>Forgot password?</a></Link></span>
                  <TextInput
                      type={"password"}
                      placeholder={"Password"}
                      required
                      name={"password"}
                      register={register}
                      disabled={submitting}
                  />
                </div>
                <div className={styles.buttonContainer}>
                  <Button className={styles.button} solid submitting={submitting}>LOG IN</Button>
                </div>
              </form>
              <LineHeader className={styles.socialSeparator}>Or</LineHeader>
              <div className={styles.verticalSeparator}>
                Or
                <div className={styles.line}/>
              </div>
              <div>
                {authRedirectError && <p className={styles.authError}>{getAuthRedirectErrorMessage()}</p>}
                <SsoButtons disabled={submitting} email/>
              </div>
            </div>
          </div>
        </main>
      </Layout>
  )
}
